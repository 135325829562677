body {
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
  
  .App {
    font-family: Arial, Helvetica, sans-serif;
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    margin-left: 0;
  }
 

  
  h1,
  h2,
  h3,
  p,
  li {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
  
  h1 {
    font-size: 18px;
  }
  
  h2 {
    font-size: 16px;
  }
  
  h3 {
    font-size: 12px;
  }
  
  p {
    font-size: 12px;
  }
  
  .info {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  
  /* styles.css */
.main-content {
  padding-top: 64px; /* Adjust based on your AppBar height */
}

@media (min-width: 600px) {
  .main-content {
    padding-top: 72px; /* Adjust based on your AppBar height for larger screens */
  }
}
.App img {
  width: 100%;
  padding-top: 30px;
  object-fit: fill; /* This ensures the image covers the entire container while maintaining aspect ratio */
  margin-top: auto;
}

.custom-slider {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 8px; /* Adjust the gap as needed */
}

.custom-slider img {
  flex: 1 1 calc(33.333% - 16px); /* This makes the images responsive */
  max-width: calc(33.333% - 16px);
  height: fit-content;
}
