/*.container__slider {
    position: relative;
    width: 900px;
    height: 600px;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: black;
  }
 */
 .container__slider {
  position: relative;
  width: 100%; /* Full width of its parent container */
  max-width: 960px; /* Maximum width */
  height: auto; /* Let the height adjust with width */
  aspect-ratio: 3 / 2; /* Maintain the aspect ratio of 900px by 600px */
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
  margin-top: 30px;
  margin-left: 0;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container__slider {
    max-width: 700px;
    aspect-ratio: 7 / 6; /* Maintain the aspect ratio of 700px by 600px */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container__slider {
    max-width: 900px;
    aspect-ratio: 3 / 2; /* Maintain the aspect ratio of 900px by 600px */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container__slider {
    max-width: 1100px;
    aspect-ratio: 11 / 6; /* Maintain the aspect ratio of 1100px by 600px */
  }
}


 
 
  .container__slider > button {
    background-color: transparent;
    outline: none;
    border: 0;
    color: #fcfcfc;
    font-size: 44px;
    font-weight: 900;
  }
  
  .container__slider > button:active {
    color: black;
  }
  
  .slider__btn-next {
    position: absolute;
    right: 0;
  }
  
  .slider__btn-prev {
    position: absolute;
    left: 0;
  }
  
  .container__slider__links {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .container__slider__links-small {
    position: relative;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: white;
    border: 1px solid grey;
    transition: background-color 2s ease;
    margin: 0 1px;
  }
  
  .container__slider__links-small-active {
    background-color: black;
  }
  
  .slider__item {
    box-sizing: border-box;
    min-width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    transform: translateX(0);
    transition: transform 1s ease;
  }
  
  .slider__item-active-1 {
    transform: translateX(0);
  }
  
  .slider__item-active-2 {
    transform: translateX(-100%);
  }
  
  .slider__item-active-3 {
    transform: translateX(-200%);
  }
  
  .slider__item-active-4 {
    transform: translateX(-300%);
  }
  
  .slider__item-active-5 {
    transform: translateX(-300%);
  }
  
  .slider__item-active-6 {
    transform: translateX(-300%);
  }
  
  .slider__item-active-7 {
    transform: translateX(-300%);
  }
  
  .slider__item img {
    width: 100%;
    height: auto;
  }
  